<template>
  <card v-if="!$_.isEmpty(user)" class="profile-card">
    <template slot="image">
      <avatar
        :name="user.name"
        :avatar="user.avatarUrl"
        :size="112"
        :uploadable="uploadable"
        :loading="uploadingAvatar"
        @click="uploadable && $refs.fileUpload.click()"
      />
    </template>

    <div slot="body">
      <static-field
        label="Name"
        action="Edit"
        :value="user.name"
        @click="changeName"
      />

      <static-field
        label="Email"
        :action="!isResellerClient && isSelf ? 'Edit' : null"
        :value="user.email"
        @click="changeEmail"
      />

      <static-field
        label="Account created"
        :value="
          $moment(user.dateCreated.toDate()).calendar(null, {
            sameElse: 'MMMM Do, YYYY \\a\\t hh:mm A'
          })
        "
      />

      <static-field
        v-if="user.dateOfLastSignIn"
        label="Last login"
        :action="$store.getters['auth/isCurrentUser'](userId) ? 'Logout' : null"
        :value="
          $moment(user.dateOfLastSignIn.toDate()).calendar(null, {
            sameElse: 'MMMM Do, YYYY \\a\\t hh:mm A'
          })
        "
        @click="$router.push({ name: 'logout' })"
      />

      <input
        id="file-input"
        ref="fileUpload"
        type="file"
        name="name"
        style="display: none;"
        @change="onFileDialogUpload"
      />
    </div>
  </card>
</template>

<script>
import { ref as storageRef, uploadBytes } from "firebase/storage";
export default {
  name: "ProfileCard",
  props: {
    userId: {
      type: String,
      required: true
    },
    uploadable: { type: Boolean, default: true }
  },
  data() {
    return {
      uploadingAvatar: false
    };
  },
  computed: {
    user() {
      return this.$store.getters["user/user"](this.userId);
    },
    isSelf() {
      return this.$store.getters["auth/isCurrentUser"](this.userId);
    },
    isResellerClient() {
      return this.$store.getters["user/isResellerUser"](this.userId);
    }
  },
  created() {
    this.$store
      .dispatch("user/observeUser", { userId: this.userId })
      .catch(this.userNotFound);
  },
  beforeDestroy() {
    this.$store.dispatch("user/unobserveUser", { userId: this.userId });
  },
  methods: {
    userNotFound() {
      console.error("User not found");
    },
    stopLoading() {
      setTimeout(() => {
        this.uploadingAvatar = false;
      }, 2000);
    },
    onFileDialogUpload(e) {
      this.uploadingAvatar = true;
      this.$_.each(e.target.files, file => {
        this.$emit("fileselected", file);
        const fileRef = storageRef(
          this.$storage(`${this.$projectId}-avatar`),
          this.userId
        );

        uploadBytes(fileRef, file, {
          contentType: file.type,
          customMetadata: {
            uid: this.userId
          }
        })
          .then(() => {
            this.stopLoading();
          })
          .catch(error => {
            this.stopLoading();
            this.$toast.open({
              message: error.message,
              type: "is-danger"
            });
          });
      });
      e.target.value = null;
    },
    changeName() {
      const modal = this.$modal.open({
        parent: this,
        component: () => import("./_changeNameModal"),
        hasModalCard: true,
        width: 500,
        canCancel: ["button"],
        props: {
          userId: this.userId,
          name: this.user.name
        },
        events: {
          changed: () => {
            modal.close();
          }
        }
      });
    },
    changeEmail() {
      if (this.isResellerClient) return;
      const modal = this.$modal.open({
        parent: this,
        component: () => import("./_changeEmailModal"),
        hasModalCard: true,
        width: 500,
        canCancel: ["button"],
        props: {
          userId: this.userId,
          email: this.user.email
        },
        events: {
          changed: () => {
            modal.close();
          }
        }
      });
    }
  }
};
</script>

<style lang="scss">
@import "~@sass/bulma/custom-variables";
.profile-card {
  .card-image {
    display: flex;
    justify-content: center;
    padding: 0 2.5rem 0;
    background-color: $primary;
    background-color: var(--primary-color);
    transition: background-color linear 0.1s;

    picture {
      bottom: -2.5rem;
      box-shadow: 0 0 1rem 0 rgba($black, 0.5);
      transition: bottom linear 0.1s;
    }
  }
  .card-content {
    padding-top: 4rem;
  }
  &:hover {
    .card-image {
      background-color: darken($primary, 5);
      background-color: var(--primary-color);
      picture {
        bottom: -2.25rem;
      }
    }
  }
}
</style>
